div.memory-bar { border-radius: 5px; border: 1px solid #ddd; float: left; }
div.memory-section { float: left; height: 30px; }

div.memory_classic  { background: #512E5F; }
div.memory_quorum  { background: #9B59B6; }
div.memory_stream  { background: #D7BDE2; }
div.memory_binary { background: url(bg-binary.png); }
div.memory_conn   { background: #dada66; }
div.memory_proc   { background: #6abf59; }
div.memory_table  { background: #6679da; }
div.memory_system { background: #999; }
div.memory_unused { background: #955; }

div.memory-bar div.memory_classic  { border-right: solid 1px #bbb; }
div.memory-bar div.memory_quorum  { border-right: solid 1px #bbb; }
div.memory-bar div.memory_stream  { border-right: solid 1px #bbb; }
div.memory-bar div.memory_binary { border-right: solid 1px #eb50a6; }
div.memory-bar div.memory_conn   { border-right: solid 1px #ebeb8d; }
div.memory-bar div.memory_proc   { border-right: solid 1px #79da66; }
div.memory-bar div.memory_table  { border-right: solid 1px #8d9ceb; }
div.memory-bar div.memory_system { border-right: solid 1px #bbb; }
div.memory-bar div.memory_unused { border-right: solid 1px #bbb; }