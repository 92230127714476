.clickableLink {
    cursor: pointer;
}

.LogSelector {
    padding-bottom: 20px;
}

.awsui awsui-date-picker {
    float: left;
}

.startTimeInput {
    width: 150px;
    display: inline-block;
}

.endTimeInput {
    width: 150px;
    display: inline-block;
}

.fullScreenModal .awsui-modal-dialog {
    max-width: 100% !important;
}